.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4; /* Light background color */
  color: #333; /* Dark text color */
}

/* Styling for <h1> (Header 1) */
h1 {
  font-size: 2rem; /* Font size for h1 */
  font-weight: bold;
  color: #333; /* Dark color for header */
  margin-bottom: 20px; /* Space below the header */
  text-align: center; /* Center align */
}

/* Styling for <p> (Paragraph) */
p {
  font-size: 1rem; /* Default font size for paragraph */
  color: #111; /* Lighter color for paragraph text */
  margin-bottom: 15px; /* Space below each paragraph */
  text-align: justify; /* Justified text alignment */
}

/* Styling for <ul> (Unordered List) */
ul {
  list-style-type: disc; /* Default bullet points */
  margin-left: 20px; /* Indentation for lists */
  padding-left: 10px; /* Padding inside the list */
}

/* Styling for <ol> (Ordered List) */
ol {
  list-style-type: decimal; /* Numbered list style */
  margin-left: 20px; /* Indentation for ordered list */
  padding-left: 10px; /* Padding inside the list */
}

/* Styling for <li> (List Items) */
li {
  margin-bottom: 10px; /* Space between list items */
  font-size: 1rem; /* Default font size for list items */
  color: #333; /* Text color for list items */
}

/* Optional: Add hover effect for list items */
li:hover {
  background-color: #e9ecef; /* Light background on hover */
  cursor: pointer; /* Change cursor to pointer */
}
